import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import Loadable from 'components/common/Loadable';
import * as AuthUtils from 'utils/auth';
import { RootRoutes } from 'utils/routeGenerator';

const Home = Loadable(lazy(() => import('components/Home')));
const Vehicle = Loadable(lazy(() => import('components/Vehicle')));
const AddEditVehicle = Loadable(lazy(() => import('components/Vehicle/AddEditVehicle')));

const CompanyDetails = Loadable(lazy(() => import('components/CompanyDetails')));

const PrivateRoute = ({ children }: { children: JSX.Element }) =>
  AuthUtils.isAuthCookiePresent() ? children : <Navigate to={RootRoutes.login()} />;

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
    },
    {
      path: '/settings',
      element: (
        <PrivateRoute>
          <CompanyDetails />
        </PrivateRoute>
      ),
    },
    {
      path: '/companies',
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
    },
    {
      path: '/companies/:companyId',
      element: (
        <PrivateRoute>
          <CompanyDetails />
        </PrivateRoute>
      ),
    },
    {
      path: '/vehicles',
      element: (
        <PrivateRoute>
          <Vehicle />
        </PrivateRoute>
      ),
    },
    {
      path: '/vehicles/add',
      element: (
        <PrivateRoute>
          <AddEditVehicle />
        </PrivateRoute>
      ),
    },
    {
      path: '/vehicles/:vehicleId/edit',
      element: (
        <PrivateRoute>
          <AddEditVehicle />
        </PrivateRoute>
      ),
    },
  ],
};

export default MainRoutes;
