import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import * as AuthUtils from 'utils/auth';
import { RootRoutes } from 'utils/routeGenerator';

const UNAUTHENTICATED_ROUTES = [{ method: 'post', path: '/login' }];

// api-client

const apiClient = axios.create({
  baseURL: process.env.VITE_SPARK_API_BASE_URL || 'http://localhost:3000/api/v1',
  timeout: 60000 * 5, //5 min
  headers: {
    'Content-Type': 'application/json',
  },
});

const isPublicUrl = ({ method, path }: any) =>
  UNAUTHENTICATED_ROUTES.some((route) => method === route.method && path === route.path);

const requestMiddleware = (config: any) => {
  if (isPublicUrl({ method: config.method, path: config.url })) {
    return config;
  }

  const token = AuthUtils.getAuthCookie();
  const employee_id = AuthUtils.getEmployeeIdCookie();
  const csrf_token = AuthUtils.getCSRFToken();

  const current_page = window.location.pathname;
  const companyId = window.localStorage.getItem('companyId');

  return {
    ...cloneDeep(config),
    headers: {
      ...config.headers,
      'x-auth-token-internal': token,
      'x-csrf-token': csrf_token,
      'x-app-identifier': 'internal',
      'x-trace-id': `spark-internal-web=${employee_id}=${current_page}=${new Date().valueOf()}`,
      'x-company-id': companyId,
    },
  };
};

const errorHandler = (error: any) => {
  if (error.response && error.response.status === 401) {
    AuthUtils.removeAuthCookie();
    AuthUtils.removeEmployeeIdCookie();
    const redirectUrl = RootRoutes.login();
    window.location.href = redirectUrl;
    return;
  }
  if (error.response && error.response.status === 403) {
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

apiClient.interceptors.request.use(requestMiddleware, (error) => Promise.reject(error));

apiClient.interceptors.response.use((response) => response, errorHandler);

export { apiClient };
