import React, { lazy } from 'react';

import AuthLayout from 'layouts/AuthLayout';
import Loadable from 'components/common/Loadable';

const LoginForm = Loadable(lazy(() => import('components/Login')));

const AuthRoutes = {
  path: '/auth',
  element: <AuthLayout />,
  children: [
    {
      path: '/auth/login',
      element: <LoginForm />,
    },
  ],
};

export default AuthRoutes;
