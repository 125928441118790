import { Outlet } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';

const AuthLayout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
