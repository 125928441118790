import React, { createContext, useContext, useState, useEffect } from 'react';
import useQueryUser from 'hooks/useQueryUser';
import * as AuthUtils from 'utils/auth';
import Loader from 'components/common/Loader';

import { User } from '../../types';

interface UserContextType {
  user: User;
  setUser: (user: User) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const isCookiePresent = AuthUtils.isAuthCookiePresent();
  const { data: userData, isLoading: isProfileLoading } = useQueryUser({
    enabled: isCookiePresent,
  });

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  if (isProfileLoading && !user) {
    return <Loader />;
  }
  const userInfo = user!;

  return <UserContext.Provider value={{ user: userInfo, setUser }}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
