import { apiClient } from 'utils/apiClient';
import { Company, RentOwner } from '../../types';

interface CompaniesResponse {
  results: Array<Company>;
  page: number;
  limit: number;
  totalCount: number;
}

interface Params {
  params: {
    page?: number;
    limit?: number;
    isActive?: boolean;
  };
}

interface RentOwnerResponse {
  results: Array<RentOwner>;
  page: number;
  limit: number;
  totalCount: number;
}

export function createWorkSite({ body }: { body: any }) {
  return apiClient.post(`/company/work-sites`, { ...body });
}

export function updateWorkSite({ workSiteId, body }: { workSiteId: string; body: any }) {
  return apiClient.patch(`/company/work-sites/${workSiteId}`, { ...body });
}

export function getCompanyById() {
  return apiClient.get<Company>(`/company/profile`);
}

export function getAllCompanies() {
  return apiClient.get<CompaniesResponse>(`/companies`);
}

export function createCompany({ body }) {
  return apiClient.post(`/companies`, { ...body });
}

export function updateCompany({ companyId, body }) {
  return apiClient.patch(`/companies/${companyId}`, { ...body });
}

// Rent Owners //

export function fetchRentOwners({ params }: Params) {
  return apiClient.get<RentOwnerResponse>(`/company/rent-owners`, { params });
}

export function addRentOwner({ body }: { body: any }) {
  return apiClient.post(`/company/rent-owners`, { ...body });
}

export function updateRentOwner({ rentOwnerId, body }: { rentOwnerId: string; body: any }) {
  return apiClient.patch(`/company/rent-owners/${rentOwnerId}`, { ...body });
}
