import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ThemeRoutes from 'routes';
import { UserProvider } from 'contexts/UserContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Loader from 'components/common/Loader';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const AllRoutes = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={theme({
          borderRadius: 12,
          opened: true,
          defaultId: 'default',
          fontFamily: "'Roboto', sans-serif",
        })}
      >
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <UserProvider>
                <ThemeRoutes />
              </UserProvider>
            </Suspense>
          </BrowserRouter>
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AllRoutes;
