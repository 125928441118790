import { useQuery } from '@tanstack/react-query';
import * as CompanyApi from 'services/company';

const getCompanies = async () => {
  const {
    data: { results },
  } = await CompanyApi.getAllCompanies();
  return results;
};

const useQueryCompanies = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getCompanies'],
    queryFn: () => getCompanies(),
  });

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useQueryCompanies;
