import cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import dayjs from 'dayjs';

const { VITE_AUTH_COOKIE_DOMAIN } = process.env;

const REDIRECT_COOKIE_NAME = 'redirect_url';
export const CSRF_COOKIE_NAME = 'x-csrf-token';
export const EMPLOYEE_ID_COOKIE_NAME = 'employee-id';
export const AUTH_COOKIE_NAME = 'x-auth-token-internal';

export const isAuthCookiePresent = () => Boolean(cookies.get(AUTH_COOKIE_NAME));

export const saveAuthCookie = (cookieValue: string) => {
  cookies.set(AUTH_COOKIE_NAME, `${cookieValue}`, {
    domain: VITE_AUTH_COOKIE_DOMAIN,
    secure: true,
    sameSite: 'Lax',
    hostOnly: true,
  });
};

export const removeAuthCookie = () => cookies.remove(AUTH_COOKIE_NAME);

export const getAuthCookie = () => cookies.get(AUTH_COOKIE_NAME);

export const getCSRFToken = () => cookies.get(CSRF_COOKIE_NAME);

export const saveEmployeeIdCookie = (employee_id: string) => cookies.set(EMPLOYEE_ID_COOKIE_NAME, employee_id);

export const saveRedirectCookie = (cookieValue: string) => cookies.set(REDIRECT_COOKIE_NAME, cookieValue);

export const getRedirectCookie = () => cookies.get(REDIRECT_COOKIE_NAME);

export const removeRedirectCookie = () => cookies.remove(REDIRECT_COOKIE_NAME);

export const getEmployeeIdCookie = () => cookies.get(EMPLOYEE_ID_COOKIE_NAME);

export const removeEmployeeIdCookie = () => cookies.remove(EMPLOYEE_ID_COOKIE_NAME);

export const isJwtExpired = (jwt: string) => {
  try {
    const { exp }: { exp: number } = jwtDecode(jwt);
    const expirationDate = dayjs(exp * 1000);
    const now = dayjs();
    return now.isAfter(expirationDate);
  } catch (error) {
    console.error(error);
    throw new Error('Error decoding JWT');
  }
};
