export const RootRoutes = Object.freeze({
  home: () => `/`,
  settings: () => `/settings`,
  companies: () => `/companies`,
  company: ({ companyId }) => `/companies/${companyId}`,
  vehicles: () => `/vehicles`,
  employees: () => `/employees`,
  workOrders: () => `/work-orders`,
  login: () => `/auth/login`,
  loginPasswordReset: () => `/auth/login/password-reset`,
});

export const EmployeeRoutes = Object.freeze({
  list: () => `/employees`,
  addEmployee: () => `/employees/add`,
  editEmployee: ({ employeeId }: { employeeId: string }) => `/employees/${employeeId}/edit`,
});

export const VehicleRoutes = Object.freeze({
  list: () => `/vehicles`,
  addVehicle: () => `/vehicles/add`,
  editVehicle: ({ vehicleId }: { vehicleId: string }) => `/vehicles/${vehicleId}/edit`,
});

export const WorkOrderRoutes = Object.freeze({
  list: () => `/workorders`,
  addWorkOrder: () => `/workorders/add`,
  editWorkOrder: ({ workOrderId }: { workOrderId: string }) => `/workorders/${workOrderId}/edit`,
  meterReadings: ({ workOrderId }: { workOrderId: string }) => `/workorders/${workOrderId}/meter-readings`,
  details: ({ workOrderId }: { workOrderId: string }) => `/workorders/${workOrderId}/bills`,
});
