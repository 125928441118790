import { apiClient } from 'utils/apiClient';

export const login = ({
  email,
  password,
  recaptcha,
}: {
  email?: string;
  password: string;
  recaptcha?: string | null;
}) =>
  apiClient
    .post(`/auth/login`, {
      email,
      password,
      recaptcha,
    })
    .then(({ data }) => {
      return data;
    });

export const generateResetLink = ({ email }: { email?: string }) =>
  apiClient
    .post(`/auth/v2/login/reset`, {
      email,
    })
    .then(({ data }) => data);

export function fetchProfile() {
  return apiClient.get(`/auth/profile`);
}

export const logout = ({ refreshToken }: { refreshToken: string }) =>
  apiClient.post(`/auth/logout`, {
    refreshToken,
  });
