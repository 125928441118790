import React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Grid,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useNavigate } from 'react-router-dom';

import useQueryCompanies from 'hooks/useQueryCompanies';
import { logout } from 'services/auth';
import * as AuthUtils from 'utils/auth';

const pages = ['Companies', 'Employees', 'Vehicles', 'Work Orders', 'Work Order Bills'];

import { RootRoutes } from 'utils/routeGenerator';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const { data: companies } = useQueryCompanies();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem('spark-internal-refresh-token') || '';
    AuthUtils.removeAuthCookie();
    await logout({
      refreshToken,
    });
    navigate(RootRoutes.login());
  };

  const companyOptions = companies || [];

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h4"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Spark Internal
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                key={'companies'}
                onClick={() => navigate(RootRoutes.companies())}
                sx={{ color: 'white', display: 'block' }}
              >
                Companies
              </Button>
              <Button
                key={'employees'}
                onClick={() => navigate(RootRoutes.employees())}
                sx={{ color: 'white', display: 'block' }}
              >
                Employees
              </Button>
              <Button
                key={'vehicles'}
                onClick={() => navigate(RootRoutes.vehicles())}
                sx={{ color: 'white', display: 'block' }}
              >
                Vehicles
              </Button>
              <Button
                key={'settings'}
                onClick={() => navigate(RootRoutes.settings())}
                sx={{ color: 'white', display: 'block' }}
              >
                Settings
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key={'profile'} onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Grid container spacing={2} maxWidth={1368} sx={{ margin: '0 auto' }}>
        <Grid container>
          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <InputLabel id="company-label">Select Company</InputLabel>
            <Select
              sx={{ width: 240 }}
              labelId="company-label"
              id="company"
              name="company"
              label="Select Company"
              placeholder="Select Company"
              value={localStorage.getItem('companyId') || ''}
              onChange={(e) => {
                if (e.target && e.target.value) {
                  localStorage.setItem('companyId', e.target.value as string);
                  window.location.reload();
                }
              }}
            >
              {companyOptions.map((company) => (
                <MenuItem key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Outlet />
      </Grid>
    </>
  );
}
export default ResponsiveAppBar;
