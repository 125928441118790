import React, { Suspense } from 'react';

// project imports
import Loader from './Loader';

const Loadable =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
